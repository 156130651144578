import { request } from "./api";

const GOODS_API = `/web_api/goods`
const GOODS_ONE_API = `/web_api/goods/`

async function getGoodsList(carType, city, withTestGoods=false) {
    var params = {
        "car_type": carType,
        "city": city,
        "with_test_goods": withTestGoods,
    };
    return await request("get", GOODS_API, {}, params);
}


async function getGoodsOne(goodsId) {
    var url = GOODS_ONE_API + goodsId;
    return await request("get", url, {}, {});
}


async function getCarAndCitySelectOptions() {
    var goods = await getGoodsList();
    var carSelectOptions = [];
    var citySelectOptions = []
    var carNames = [];
    var cityNames = [];
    console.log("goods", goods);
    for (var i = 0; i < goods.length; i++) {
        carNames.push(goods[i]["car_type"]);
        cityNames.push(goods[i]["city"]);
    }
    carNames = unique(carNames);
    cityNames = unique(cityNames);
    cityNames.push("北京市", "上海市", "深圳市", "广州市", "重庆市", "苏州市", "成都市", "杭州市");
    // carNames.sort(function(a,b){return a.localeCompare(b)});
    // cityNames.sort(function(a,b){return a.localeCompare(b)});
    for (i = 0; i < carNames.length; i++) {
        // NOTE: 不显示 “不区分车型”
        if (carNames[i] === "不区分车型") {
            continue
        }

        carSelectOptions.push({
            value: carNames[i],
            label: carNames[i]
        })
    }
    for (i = 0; i < cityNames.length; i++) {
        citySelectOptions.push({
            value: cityNames[i],
            label: cityNames[i]
        })
    }
    return {
        "carSelectOptions": carSelectOptions,
        "citySelectOptions": citySelectOptions,
    }
}


function unique(arr){            
    for(var i=0; i<arr.length; i++){
        for(var j=i+1; j<arr.length; j++){
            if(arr[i]==arr[j]){
                arr.splice(j,1);
                j--;
            }
        }
    }
    return arr;
}

export {
    getGoodsList,
    getGoodsOne,
    getCarAndCitySelectOptions,
}
